import React, { useState } from 'react';
import { TextField, Button, Grid, Container, Typography, Paper, FormLabel } from '@mui/material';
import './Landing.css';
// Form Component to collect user information
const LandingPage = ({ onSubmit }) => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      setPhoneError('Numero Non Valido');
      return;
    }
    onSubmit({ name, surname, email, phone });
  };
  const checkPhone = (e) => {
      setPhone(e.target.value);
  };
  return (
    <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4, backgroundColor: 'transparent', width: '100%',  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign:"center", height: '100%' }}>
        <Typography variant="h1"  gutterBottom>Benvenuto a Get #Frankthebear!</Typography>
        <br/>
        <Typography variant="body1"  gutterBottom>
          Preparati a giocare a <span style={{color:"#db37b4"}}>Get #Frankthebear</span> ! Colpisci le Frank, evita le bombe e prendi le toy champagne per punti extra.
        </Typography>
        <Typography variant="h5"  gutterBottom>Come si gioca:</Typography>
            <br/>
          <Typography variant='body2' >Clicca su #Frankthebear per guadagnare  <span style={{color:"#db37b4"}}>1 punto</span></Typography>
          <Typography variant='body2'>Clicca sulla bottiglia di champagne per guadagnare  <span style={{color:"#db37b4"}}>2 punti</span></Typography>
          <Typography variant='body2'>Evita la bomba ed il triangolo o <span style={{color:"#db37b4"}}>GAME OVER</span></Typography>
          <br/>
          <Typography variant="p2" >Raggiungi 30 punti e ricevi l'invito all'evento!!!</Typography>
        
        <Typography variant="p"  gutterBottom>Inserisci i tuoi dati per iniziare a giocare:</Typography>
        <br/>
        {phoneError && (
          <FormLabel error sx={{ color: 'red', marginBottom: 1 }}>
            {phoneError}
          </FormLabel>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                slotProps={{
                    input:{
                        style :{
                            color: 'white', // Set input text color to white
                          },
                    },
                    inputLabel: {
                        style: {
                            color: 'white', // Set the label color to white (optional)
                          },
                    }
                }}
                // InputProps={{
                //     style: {
                //       color: 'white', // Set input text color to white
                //     },
                //   }}
                //   InputLabelProps={{
                //     style: {
                //       color: 'white', // Set the label color to white (optional)
                //     },
                //   }}
                
                
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="surname"
                variant="outlined"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                required
                slotProps={{
                    input:{
                        style :{
                            color: 'white', // Set input text color to white
                          },
                    },
                    inputLabel: {
                        style: {
                            color: 'white', // Set the label color to white (optional)
                          },
                    }
                }}
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
                slotProps={{
                    input:{
                        style :{
                            color: 'white', // Set input text color to white
                          },
                    },
                    inputLabel: {
                        style: {
                            color: 'white', // Set the label color to white (optional)
                          },
                    }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Whatsapp"
                variant="outlined"
                value={phone}
                onChange={checkPhone}
                type="tel"
                required
                slotProps={{
                    input:{
                        style :{
                            color: 'white', // Set input text color to white
                          },
                    },
                    inputLabel: {
                        style: {
                            color: 'white', // Set the label color to white (optional)
                          },
                    }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained"  fullWidth>
                Gioca con frank
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};
export default LandingPage;